import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#root');
}

interface Props extends Omit<ReactModal['props'], 'portalClassName' | 'isOpen' | 'contentLabel'> {
  open: boolean;
  label?: string;
  className?: string;
  modalClassName?: string;
}

const BaseModal = ({ className, modalClassName, open, label, ...rest }: Props) => {
  const [contentElement, setContentElement] = useState<HTMLElement>();

  useEffect(() => {
    if (contentElement) {
      disableBodyScroll(contentElement);
    }

    return () => contentElement && enableBodyScroll(contentElement);
  }, [contentElement]);

  return (
    <ReactModal
      portalClassName={className}
      className={modalClassName}
      overlayRef={setContentElement}
      contentLabel={label}
      isOpen={open}
      {...rest}
    />
  );
};

export default BaseModal;

// Warning:
// We need to import and expose these exports from a single place in our app because 'body-scroll-lock' library does side effects on import and
// new <script> content ends up on our website which messes up our CSP rules(allowed scripts/hashes) and the whole app fails to load.
export { disableBodyScroll, enableBodyScroll };
